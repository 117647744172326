import React, { useContext } from 'react'
import CheckoutContext from '../../context/CheckoutContext'
import CartItem from './CartItem'

function CartItemList() {
    const { available, lineItems } = useContext(CheckoutContext)
    return <div className="py-3">{!!available && lineItems.map(CartItem)}</div>
}

export default CartItemList
