import React, { useContext } from 'react'
import CheckoutContext from '../../context/CheckoutContext'
import LocaleContext from '../../context/LocaleContext'
import { LineItem } from '../../domain/checkout'
import { localiseMoney } from '../../domain/locale'
import { ChevronUpIcon, ChevronDownIcon, XIcon } from '@heroicons/react/solid'

function CartItem({ variantId, name, price, quantity, imageUrl }: LineItem) {
    const { locale } = useContext(LocaleContext)
    const { incrementLineItem, decrementLineItem, removeLineItem } =
        useContext(CheckoutContext)

    const increment = async () => await incrementLineItem(variantId)
    const decrement = async () => await decrementLineItem(variantId)
    const remove = async () => await removeLineItem(variantId)

    const total = quantity * price
    const formattedUnitPrice = localiseMoney(price, locale)
    const formattedLineTotal = localiseMoney(total, locale)

    return (
        <div className="md:bg-gainsboro md:rounded-lg flex items-center py-3 md:py-6 md:px-6 md:mb-3">
            <div className="w-3/5 flex flex-grow items-center">
                <div className="h-16 md:h-20 w-16 md:w-20 mr-4 bg-gainsboro p-1 rounded-lg">
                    <img src={imageUrl} alt={name} />
                </div>

                <div>
                    <h4 className="text-slategray font-medium text-sm md:text-base">
                        {name}
                    </h4>
                    <button
                        className="text-lightgray hover:text-slategray text-xs flex items-center focus:outline-none"
                        onClick={remove}
                    >
                        <XIcon className="fill-current w-3 h-3 mr-1" />
                        Remove
                    </button>
                </div>
            </div>

            <div className="hidden md:flex flex-col items-center ml-auto">
                <button
                    className="text-slategray hover:text-primary focus:outline-none p-1"
                    onClick={increment}
                >
                    <ChevronUpIcon className="w-4 h-4 fill-current" />
                </button>

                <span className="mx-3 md:mx-6 p-1">{quantity}</span>

                <button
                    className="text-slategray hover:text-primary focus:outline-none p-1"
                    onClick={decrement}
                >
                    <ChevronDownIcon className="w-4 h-4 fill-current" />
                </button>
            </div>

            <div className="text-right md:w-1/5">
                <p className="font-medium text-slategray">
                    {formattedLineTotal}
                </p>
                {quantity > 1 && (
                    <p className="text-lightgray text-sm">
                        {formattedUnitPrice} each
                    </p>
                )}
            </div>
        </div>
    )
}

export default CartItem
