import React, { useContext } from 'react'
import CheckoutContext from '../../context/CheckoutContext'
import LocaleContext from '../../context/LocaleContext'
import { localiseMoney } from '../../domain/locale'
import CartItemList from './CartItemList'

type Props = {
    message?: string
}

function Cart({ message = '' }: Props) {
    const { locale } = useContext(LocaleContext)
    const { available, loading, lineItems, subtotalPrice, checkoutUrl } =
        useContext(CheckoutContext)

    // If checkout provider is loading and it's not available yet, show loading screen
    if (!!loading && !available) {
        return <p>Loading...</p>
    }

    if (lineItems.length < 1) return <p>Your cart is empty</p>

    return (
        <React.Fragment>
            <CartItemList />

            <div className="mt-3 md:mt-6 py-3 md:py-6 border-t-2 border-gainsboro">
                <div className="flex flex-row justify-between">
                    <div className="max-w-xs sm:max-w-md mr-10 flex-wrap">
                        <p className="text-gray-600 text-sm sm:text-base">
                            {message}
                        </p>
                    </div>
                    <div>
                        <div className="flex flex-col max-w-sm items-end mb-3">
                            <React.Fragment>
                                <span className="text-slategray">
                                    Sub total
                                </span>
                                <span className="text-xl font-bold text-primary">
                                    {localiseMoney(subtotalPrice, locale)}
                                </span>
                            </React.Fragment>
                        </div>

                        <a
                            href={checkoutUrl}
                            className="bg-primary hover:bg-slategray px-4 py-3 rounded-lg text-white text-sm font-bold tracking-widest uppercase focus:outline-none"
                        >
                            Checkout
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Cart
